// import { AddIcon } from "@chakra-ui/icons";
import { Box, Stack, Text } from "@chakra-ui/layout";
import { useToast } from "@chakra-ui/toast";
import axios from "axios";
import { Input } from "@chakra-ui/input";
import { useEffect, useState } from "react";
import { getSender, isAdmin } from "../config/ChatLogics";
import ChatLoading from "./ChatLoading";
// import GroupChatModal from "./miscellaneous/GroupChatModal";
// import { Button } from "@chakra-ui/react";
import { ChatState } from "../Context/ChatProvider";
import {
  Select,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Button,
  Alert,
  AlertIcon,
  Spacer,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";

const MyChats = ({ fetchAgain }) => {
  const [loggedUser, setLoggedUser] = useState();
  const [currentScenario, setCurrentScenario] = useState();
  const [scenarioOptions, setScenarioOptions] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { selectedChat, setSelectedChat, user, chats, setChats } = ChatState();
  const [counter, setCounter] = useState(0);
  const [error, setError] = useState("");

  const toast = useToast();

  const fetchChats = async () => {
    // Set the current scenario during init and query the correct chats
    // setScenarioOptions(["starmall", "galaxyX", "small scen 2"]);
    setScenarioOptions(["starmall"]);
    setCurrentScenario("starmall");

    // console.log(user._id);
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };

      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/chat`, config);
      setChats(data);
      console.log(data);
    } catch (error) {
      toast({
        title: "Error Occured!",
        description: "Failed to Load the chats",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom-left",
      });
    }
  };

  useEffect(() => {
    setLoggedUser(JSON.parse(localStorage.getItem("userInfo")));
    fetchChats();
    // eslint-disable-next-line
  }, [fetchAgain]);

  const onSelectScenario = async (scenario) => {
    setCurrentScenario(scenario);
    // Set the chats to the correct scenarios
    if (scenario !== "starmall") {
      console.log("not starmall")
      // Fake chat data for now
      // const chatData = {
      //   galaxyX: [
      //     {
      //       isGroupChat: true,
      //       users: [
      //         {
      //           pic: "https://icon-library.com/images/anonymous-avatar-icon/anonymous-avatar-icon-25.jpg",
      //           isAdmin: false,
      //           _id: "6630b3261ab9bb14d418c3ac",
      //           name: "CHATGPT",
      //           email: "user1@gmail.com",
      //           __v: 0,
      //         },
      //         {
      //           pic: "https://icon-library.com/images/anonymous-avatar-icon/anonymous-avatar-icon-25.jpg",
      //           isAdmin: false,
      //           _id: "6630b4711ab9bb14d418c3ba",
      //           name: "user2",
      //           email: "user2@gmail.com",
      //           __v: 0,
      //         },
      //         {
      //           pic: "https://icon-library.com/images/anonymous-avatar-icon/anonymous-avatar-icon-25.jpg",
      //           isAdmin: false,
      //           _id: "6630b2e31ab9bb14d418c3ab",
      //           name: "timothy",
      //           email: "timothykerong@gmail.com",
      //           __v: 0,
      //         },
      //       ],
      //       _id: "6630b34f1ab9bb14d418c3ad",
      //       chatName: "Project A",
      //       groupAdmin: {
      //         pic: "https://icon-library.com/images/anonymous-avatar-icon/anonymous-avatar-icon-25.jpg",
      //         isAdmin: false,
      //         _id: "6630b2e31ab9bb14d418c3ab",
      //         name: "timothy",
      //         email: "timothykerong@gmail.com",
      //         __v: 0,
      //       },
      //       createdAt: "2024-04-30T09:01:03.903Z",
      //       updatedAt: "2024-05-16T01:34:21.124Z",
      //       __v: 0,
      //       latestMessage: {
      //         readBy: [],
      //         _id: "6645629d0d416b37c0a0236a",
      //         sender: {
      //           pic: "https://icon-library.com/images/anonymous-avatar-icon/anonymous-avatar-icon-25.jpg",
      //           _id: "6630b3261ab9bb14d418c3ac",
      //           name: "CHATGPT",
      //           email: "user1@gmail.com",
      //         },
      //         content: "this is the gpt response1",
      //         chat: "6630b34f1ab9bb14d418c3ad",
      //         createdAt: "2024-05-16T01:34:21.118Z",
      //         updatedAt: "2024-05-16T01:34:21.118Z",
      //         __v: 0,
      //       },
      //     },
      //   ],
      //   "small scen 2": [
      //     {
      //       isGroupChat: false,
      //       users: [
      //         {
      //           pic: "https://icon-library.com/images/anonymous-avatar-icon/anonymous-avatar-icon-25.jpg",
      //           isAdmin: false,
      //           _id: "6630b4711ab9bb14d418c3ba",
      //           name: "user2",
      //           email: "user2@gmail.com",
      //           __v: 0,
      //         },
      //         {
      //           pic: "https://icon-library.com/images/anonymous-avatar-icon/anonymous-avatar-icon-25.jpg",
      //           isAdmin: false,
      //           _id: "6630b2e31ab9bb14d418c3ab",
      //           name: "timothy",
      //           email: "timothykerong@gmail.com",
      //           __v: 0,
      //         },
      //       ],
      //       _id: "664206691a0db13c8cb808b8",
      //       chatName: "sender",
      //       createdAt: "2024-05-13T12:24:09.368Z",
      //       updatedAt: "2024-05-16T01:30:58.786Z",
      //       __v: 0,
      //       latestMessage: {
      //         readBy: [],
      //         _id: "664561d20d416b37c0a02366",
      //         sender: {
      //           pic: "https://icon-library.com/images/anonymous-avatar-icon/anonymous-avatar-icon-25.jpg",
      //           _id: "6630b2e31ab9bb14d418c3ab",
      //           name: "timothy",
      //           email: "timothykerong@gmail.com",
      //         },
      //         content: "hi",
      //         chat: "664206691a0db13c8cb808b8",
      //         createdAt: "2024-05-16T01:30:58.774Z",
      //         updatedAt: "2024-05-16T01:30:58.774Z",
      //         __v: 0,
      //       },
      //     },
      //   ],
      // };  
      // setChats(chatData[scenario])
    } else {
      // Fetch chat
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };

      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/api/chat`, config);
      setChats(data);
    }
  };

  const handleToggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const setChatCounter = async (event) => {
    const config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    const { data } = await axios.put(
      `${process.env.REACT_APP_API_URL}/api/chat/counter`,
      {
        counter: event,
        userId: user._id,
      },
      config
    );

    if (data) {
      setCounter(0);
      return
    }

    setError("Failed to set counter, please try again later")
    setTimeout(() => {
      setError('')
    }, 5000);
  };

  return (
    <Box
      d={{ base: selectedChat ? "none" : "flex", md: "flex" }}
      flexDir="column"
      alignItems="center"
      p={3}
      bg="white"
      w={{ base: "100%", md: "31%" }}
      borderRadius="lg"
      borderWidth="1px"
    >
      <Box
        pb={3}
        px={3}
        fontSize={{ base: "28px", md: "30px" }}
        fontFamily="Work sans"
        d="flex"
        w="100%"
        justifyContent="space-between"
        alignItems="center"
      >
        My Chats
        <Spacer />
        {isAdmin(user) && (
          <>
            <Input
            type="number"
            value={counter}
            onChange={(e) => setCounter(e.target.value)}
            placeholder="Enter value"
            mr={2}
            w="60px"
          />
          <Button
            colorScheme="teal"
            size="sm"
            onClick={() => setChatCounter(parseInt(counter))}
            disabled={!counter || counter === 0}
            mr={2}
          >
            Set
          </Button>
          </>
        )}
        {error && (
          <Alert status="error" mt={4}>
            <AlertIcon />
            {error}
          </Alert>
        )}
        {/* <GroupChatModal>
          <Button
            d="flex"
            fontSize={{ base: "17px", md: "10px", lg: "17px" }}
            rightIcon={<AddIcon />}
          >
            New Group Chat
          </Button>
        </GroupChatModal> */}
      </Box>
      <Box
        pb={3}
        px={3}
        fontSize={{ base: "20px", md: "20px" }}
        fontFamily="Work sans"
        d="flex"
        w="100%"
        justifyContent="space-between"
        alignItems="center"
      >
        <Text as="b" color="green">
          Scenario: {currentScenario}
        </Text>
        <HamburgerIcon onClick={handleToggleModal} />
      </Box>
      <Modal isOpen={isModalOpen} onClose={handleToggleModal}>
        <ModalOverlay />
        <ModalContent  h="40vh">
          <ModalHeader>Select Scenario</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Select
              value={currentScenario}
              onChange={(e) => {
                onSelectScenario(e.target.value);
                handleToggleModal(); // Close modal after selection
              }}
            >
              {scenarioOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </Select>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Box
        d="flex"
        flexDir="column"
        p={3}
        bg="#F8F8F8"
        w="100%"
        h="100%"
        borderRadius="lg"
        overflowY="hidden"
      >
        {chats ? (
          <Stack overflowY="scroll">
            {chats.map((chat) => (
              <Box
                onClick={() => setSelectedChat(chat)}
                cursor="pointer"
                bg={selectedChat === chat ? "#38B2AC" : "#E8E8E8"}
                color={selectedChat === chat ? "white" : "black"}
                px={3}
                py={2}
                borderRadius="lg"
                key={chat._id}
              >
                <Text>
                  {!chat.isGroupChat
                    ? getSender(loggedUser, chat.users)
                    : chat.chatName}
                </Text>
                {chat.latestMessage && (
                  <Text fontSize="xs">
                    <b>{chat.latestMessage.sender.name.includes("_bot")
                        ? chat.latestMessage.sender.name.split("_")[1]
                        :chat.latestMessage.sender.name}: </b>
                    {chat.latestMessage.content.length > 50
                      ? chat.latestMessage.content.substring(0, 51) + "..."
                      : chat.latestMessage.content}
                  </Text>
                )}
              </Box>
            ))}
          </Stack>
        ) : (
          <ChatLoading />
        )}
      </Box>
    </Box>
  );
};

export default MyChats;
