import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";
import Homepage from "./Pages/Homepage";
import Chatpage from "./Pages/Chatpage";
import ResetPasswordPage from "./Pages/ResetPasswordPage";
import NewPasswordPage from "./Pages/NewPasswordPage";

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/" component={Homepage} exact />
          <Route path="/chats" component={Chatpage} />
          <Route path="/reset-password/:token" component={NewPasswordPage} />
          <Route path="/reset-password" component={ResetPasswordPage} exact />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
