import { Avatar } from "@chakra-ui/avatar";
import { Tooltip } from "@chakra-ui/tooltip";
import ScrollableFeed from "react-scrollable-feed";
import {
  isLastMessage,
  isSameSender,
  isSameSenderMargin,
  isSameUser,
  isAdmin,
} from "../config/ChatLogics";
import { ChatState } from "../Context/ChatProvider";

const ScrollableChat = ({ messages }) => {
  const { user } = ChatState();

  return (
    <ScrollableFeed>
      {messages &&
        messages.map((m, i) => {
          const showName = !isSameSender(messages, m, i, user._id) || !isSameUser(messages, m, i, user._id);
          const sname = (m.sender.name.includes("_bot")) ? m.sender.name.split("_")[1] : m.sender.name;
          const senderName = sname.length > 15 ? sname.slice(0, 15) + "..." : sname;
          const owner = isSameSenderMargin(messages, m, i, user._id) === "auto" ? true : false
          
          return (
            <div style={{ display: "flex", alignItems:"center"}} key={m._id}>
              {(isSameSender(messages, m, i, user._id) ||
                isLastMessage(messages, i, user._id)) && (
                <Tooltip label={m.sender.name} placement="bottom-start" hasArrow>
                  <Avatar
                    mt="7px"
                    mr={1}
                    size="sm"
                    cursor="pointer"
                    name={m.sender.name}
                    src={m.sender.pic}
                  />
                </Tooltip>
              )}
              <span
                style={{
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: `${
                    m.sender._id === user._id ? "#BEE3F8" : isAdmin(m.sender)  ?  "#EDE90C" : "#B9F5D0"
                  }`,
                  marginLeft: isSameSenderMargin(messages, m, i, user._id),
                  marginTop: isSameUser(messages, m, i, user._id) ? 3 : 10,
                  borderRadius: "20px",
                  padding: "5px 15px",
                  maxWidth: "75%",
                }}
              >
                {showName && !owner && (
                  <span style={{ fontWeight: "bold", marginBottom: "5px" }}>
                    {senderName}
                  </span>
                )}
                <span>{m.content}</span>
              </span>
            </div>
          );
        })}
    </ScrollableFeed>
  );
};

export default ScrollableChat;
