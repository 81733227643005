import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Container,
  Link, 
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
import axios from "axios";

function ResetPasswordPage() {
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const handleSubmit = async (event) => {
    setButtonDisabled(true)
    event.preventDefault();
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/user/resetpassword`, {
        email: email
      });

      if (response.request.status !== 200){
        setSuccess(false);
        setError("Failed to reset password. Please try again.");
      }

      setSuccess(true);
      setError("");
    } catch (err) {
      setSuccess(false);
      setError("Failed to reset password. Please try again.");
    }
    setButtonDisabled(false)
  };

  return (
    <Container maxW="xl" centerContent>
      <Box
        justifyContent="center"
        p={5}
        bg="white"
        w="100%"
        m="40px 0 15px 0"
        borderRadius="lg"
        borderWidth="1px"
      >
        <form onSubmit={handleSubmit}>
          <Stack spacing={4}>
            <FormControl id="email" isRequired>
              <FormLabel>Email address</FormLabel>
              <Input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
              />
            </FormControl>
            <Button type="submit" colorScheme="blue"
            disabled={buttonDisabled}>
              Reset Password
            </Button>
          </Stack>
        </form>
        {success && (
          <Alert status="success" mt={4}>
            <AlertIcon />
            Password reset link has been sent to your email.
          </Alert>
        )}
        {error && (
          <Alert status="error" mt={4}>
            <AlertIcon />
            {error}
          </Alert>
        )}
      </Box>
      <Link as={RouterLink} to="/">
        <Button>Go to Home Page</Button>
      </Link>
    </Container>
  );
}

export default ResetPasswordPage;