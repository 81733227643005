import React, { useState } from "react";
import { useParams, Link as RouterLink } from "react-router-dom";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Container,
  Alert,
  AlertIcon,
  Link, 
} from "@chakra-ui/react";
import axios from "axios";

function NewPasswordPage() {
  const { token } = useParams(); // Capture the token from the URL
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const handleSubmit = async (event) => {
    setButtonDisabled(true)
    event.preventDefault();
    const passwordError = validatePassword(password, confirmPassword);
    if (passwordError) {
      setError(passwordError);
      setSuccess(false);
    } else {
      setError("");
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/user/resetpassword/${token}`,
          {
            new_password: password,
          }
        );
        if (response.request.status !== 200) {
          setSuccess(false);
          setError("Failed to reset password. Please try again.");
        }

        setSuccess(true);
        setError("");
      } catch (apiError) {
        setError("Failed to reset password. Please try again.");
        setSuccess(false);
      }
    }
  };

  const validatePassword = (password, confirmPassword) => {
    if (password.length < 8)
      return "Password must be at least 8 characters long.";
    if (!/[A-Z]/.test(password))
      return "Password must contain at least one capital letter.";
    if (!/[a-z]/.test(password))
      return "Password must contain at least one small letter.";
    if (!/[0-9]/.test(password))
      return "Password must contain at least one number.";
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(password))
      return "Password must contain at least one special character.";
    if (password !== confirmPassword) return "Passwords do not match.";
    return null;
  };

  return (
    <Container maxW="xl" centerContent>
      <Box
        justifyContent="center"
        p={5}
        bg="white"
        w="100%"
        m="40px 0 15px 0"
        borderRadius="lg"
        borderWidth="1px"
      >
        <form onSubmit={handleSubmit}>
          <Stack spacing={4}>
            <FormControl id="password" isRequired>
              <FormLabel>New Password</FormLabel>
              <Input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter your new password"
              />
            </FormControl>
            <FormControl id="confirm-password" isRequired>
              <FormLabel>Confirm New Password</FormLabel>
              <Input
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder="Confirm your new password"
              />
            </FormControl>
            <Button type="submit" colorScheme="blue" disabled={buttonDisabled}>
              Reset Password
            </Button>
          </Stack>
        </form>
        {error && (
          <Alert status="error" mt={4}>
            <AlertIcon />
            {error}
          </Alert>
        )}
        {success && (
          <Alert status="success" mt={4}>
            <AlertIcon />
            Password has been reset successfully.{" "}
          </Alert>
        )}
        <Link as={RouterLink} to="/">
          <Button>Go to Home Page</Button>
        </Link>
      </Box>
    </Container>
  );
}

export default NewPasswordPage;
